(function() {
    $(document).on('turbolinks:load', function() {

      console.log("ASD");
      $("#summernote").summernote();

      $('#target_language').multiselect({
        enableFiltering: true,
        filterBehavior: 'text',
        buttonContainer: '<div class="btn-group d-block"></div>',
        buttonClass: 'btn btn-default btn-block',
        filterClearBtn: "<span></span>",
        includeFilterClearBtn: false,
        enableCaseInsensitiveFiltering: true,
        nonSelectedText: 'Select the type of events'
      });
      $('#proficient_language').multiselect({
        enableFiltering: true,
        buttonContainer: '<div class="btn-group d-block"></div>',
        buttonClass: 'btn btn-default btn-block',
        filterClearBtn: "<span></span>",
        includeFilterClearBtn: false,
        filterBehavior: 'text',
        nonSelectedText: 'Select a language'
      });
    });
  
    $(document).on('page:fetch', function() {
      $('#target_language').multiselect({
        enableFiltering: true,
        buttonClass: 'btn btn-link btn-block',
        filterBehavior: 'text',
        filterClearBtn: "<span></span>",
        includeFilterClearBtn: false,
        enableCaseInsensitiveFiltering: true,
        nonSelectedText: 'Select the type of events'
      });
      $('#proficient_language').multiselect({
        enableFiltering: true,
        buttonClass: 'btn btn-link btn-block',
        filterBehavior: 'text',
        filterClearBtn: "<span></span>",
        includeFilterClearBtn: false,
        nonSelectedText: 'Select a language'
      });
    });
  
    $(document).on('turbolinks:before-cache', function() {
      var removeProficient, removeTarget;
      removeTarget = $('#target_language');
      removeProficient = $('#proficient_language');
      $(removeTarget).replaceWith($(removeTarget).contents('#target_language'));
      $(removeProficient).replaceWith($(removeProficient).contents('#proficient_language'));
    });
  
  }).call(this);

  (function() {
    var ready;
  
    ready = function() {
      $('form').on('click', '.remove_fields', function(event) {
        $(this).prev('input[type=hidden]').val('true');
        $(this).closest('fieldset').hide();
        return event.preventDefault();
      });
      $('form').on('click', '.add_fields', function(event) {
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
      });
      $('.carousel').carousel();
      $().tab();
      $('.mdb-select').material_select();
      $("div.wordlist").each(function() {});
      return $(this).find("div.word-item").each(function() {
        var em, pattern, term;
        term = $(this).find("h5.term").html();
        em = $(this).find("em").html();
        pattern = new RegExp("(" + term + ")", "i");
        return $(this).find("em.example").html(em.replace(pattern, "<mark>$1</mark>"));
      });

      console.log("ASD");
      $("#summernote").summernote();

    };
  
    $(document).on('turbolinks:load', ready);
  
  }).call(this);