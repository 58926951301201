/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

/**
 * Refers to app/frontend/stylesheets/application.scss (or application.css)
 * Note that we don't need to preface this path with "app/frontend" due to the `source_path` config set in config/webpacker.yml. Magical!
 * The file extension can be left off due to the `extensions` config in config/webpacker.yml.
 */
import "../stylesheets/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


require.context('../stylesheets', true, /\.sass$/)

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")
// require('ckeditor4')
// const jquery = require("jquery")
// const descriptor = { value: jquery, writable: false, configurable: false }
// Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
const descriptor = { value: jquery, writable: true, configurable: true }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })


require("bootstrap")
// require('bootstrap-multiselect')
require('jqcloud2')
// require('hammerjs')
// require('waves')
// MDB
require('summernote/dist/summernote-bs4')
// require('summernote');

require("vendor/mdb")
require('vendor/bm')
require("vendor/scripts")
require("vendor/stisla")


